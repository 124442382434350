<template>
  <div>
    <div>
      <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/yeguang.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">成为会员</div>
        </div>
      </div>
      <div class="inputFrom"
        v-if="verifyshow">
        <div>社区</div>
        <div>
          <input v-model="idList.OCodeName"
            type="text"
            @click="OCodeshow = true"
            readonly="readonly"
            placeholder="请选择您社区" />
          <van-popup v-model="OCodeshow"
            position="bottom"
            get-container="body">
            <van-cascader title="请选择社区"
              :options="ocodelist"
              @close="OCodeshow = false"
              :field-names="{
                text: 'OrganName',
                value: 'OrganCode',
                children: 'children',
              }"
              @finish="onocode" />
          </van-popup>
        </div>
        <div>所属网格</div>
        <div>
          <input v-model="idList.GIdTitle"
            type="text"
            @click="idListGId = true"
            readonly="readonly"
            placeholder="请选择您所在网格" />
          <van-popup v-model="idListGId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="gidlist"
              value-key="Title"
              @cancel="idListGId = false"
              @confirm="onidListGId">
            </van-picker>
          </van-popup>
        </div>

        <div>姓名</div>
        <div>
          <input v-model="idList.Name"
            placeholder="请输入您的姓名" />
        </div>
        <div>身份证号</div>
        <div>
          <input v-model="idList.IdCard"
            placeholder="请输入身份证号" />
        </div>
        <div>手机号</div>
        <div>
          <input v-model="idList.Mobile"
            placeholder="请输入手机号" />
        </div>
      </div>
      <div class="buttons"
        v-if="verifyshow">
        <van-button style="border: 1px solid #e6e6e6"
          type="default"
          @click="fanhui">返回</van-button>
        <van-button type="info"
          @click="huiyuan">提交</van-button>
      </div>
      <div v-if="!verifyshow">
        <van-cell-group>
          <van-field label="社区"
            :value="mumberXq.OName"
            readonly />
          <van-field label="所属网格"
            :value="mumberXq.GName"
            readonly />
          <van-field label="姓名"
            :value="mumberXq.Name"
            readonly />
          <van-field label="身份证号"
            :value="mumberXq.IdCard"
            readonly />
          <van-field label="手机号"
            :value="mumberXq.Mobile"
            readonly />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WeGetGridList,
  GetCodeEntrysList,
  WxApplyLCResident,
  WeGetMemberInfo,
  WeGetAppletOrganList,
  WeGetLCMemberInfo
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      gidlist: [],
      NativeCantonCodedata: [],
      ocodelist: [],
      idList: {
        OCode: "",
        GId: undefined,
        Name: "", //姓名、
      },
      idListGId: false,
      addshow: false,
      verifyshow: true,
      LiveCity: false,
      Political: false,
      OCodeshow: false,
      mumberXq: "",

    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.MemberInfo();
    this.gitocodelist();
  },
  methods: {
    // 获取会员提交记录
    // getOpenId()
    MemberInfo () {
      WeGetLCMemberInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == '0') {
          this.verifyshow = false
          this.mumberXq = res.data.data
          console.log(res.data.data.Status);
          if (res.data.data.Status == '3') {
            this.verifyshow = true
          }
        } else if (res.data.code == '2') {
          Toast.fail(res.data.msg);
          this.verifyshow = true
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
    // 提交
    huiyuan: function () {
      this.idList.OpenID = getOpenId();
      console.log(getOpenId());
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      if (this.idList.GIdTitle == undefined) {
        Toast.fail("请选择所属网格");
        return false;
      }
      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      if (reg.test(this.idList.IdCard) == false) {
        Toast.fail("请输入正确的身份证号");
        return false;
      }
      this.idList.OpenID = getOpenId();
      WxApplyLCResident(this.idList).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            if (window.__wxjs_environment === "miniprogram") {
              window.wx.miniProgram.reLaunch({
                url: "/pages/index/index",
              });
            } else {
              alert("请在小程序中打开");
            }
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    fanhui: function () {
      window.wx.miniProgram.reLaunch({
        url: "/pages/index/index",
      });
    },
    gitocodelist () {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    ridpush: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },

    // 小区(组)选择器
    onidListGId (val) {
      this.idList.GId = val.GId;
      this.idList.GIdTitle = val.Title;
      this.idListGId = false;
    },

    // 社区
    onocode (val) {
      console.log(val);
      this.idList.OCodeName = val.selectedOptions[0].OrganName;
      this.idList.OCode = val.value;
      // this.ridpush(val.value);
      this.getGridList(val.value);
      this.OCodeshow = false;
    },
    // 网格
    getGridList: function (row) {
      WeGetGridList({ organCode: row }).then((res) => {
        this.gidlist = res.data.data;
      });
    },
    // 地址
    onLiveCity: function (value) {
      var RegCity = this.addressfrom(value);
      this.idList.NativeCantonCode = RegCity[0];
      this.idList.NativeCantonName = RegCity[1];
      this.LiveCity = false;
    },
    onPolitical (val) {
      this.idList.PoliticalCode = val.Coding;
      this.idList.PoliticalName = val.Name;
      this.Political = false;
    },
    addressfrom (value) {
      // 地址
      var a, b, c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + "," + b.text + "," + c.text;
      var address = [addressCode, addressName];
      return address;
    },
    // 按钮
    dengji () {
      if (this.idList.RIdTitle == undefined) {
        Toast.fail("请选择所住小区");
        return false;
      }
      if (this.idList.Name == "") {
        Toast.fail("请输入您的姓名");
        return false;
      }
      this.addshow = true;
      this.verifyshow = false;
    },
    pageshow () {
      this.addshow = false;
      this.verifyshow = true;
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>